@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-4xl leading-10 font-extrabold tracking-tight;
    }

    input[type="radio"],
    input[type="radio"]:checked:focus {
        @apply w-8 h-8 border-gray-300 border-2 text-purple-heart bg-transparent;
    }

    input[type="radio"]:checked,
    input[type="radio"]:checked:hover {
        @apply bg-transparent border-gray-300 text-purple-heart;
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%234E34D7' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='2.5'/%3e%3c/svg%3e");
    }
    /* 
	input[type="checkbox"],
	input[type="checkbox"]:checked:focus {
		@apply rounded w-8 h-8 border-gray-300 border-2 text-purple-heart bg-transparent;
	} */

    input[type="checkbox"]:checked,
    input[type="checkbox"]:checked:hover {
        /* @apply bg-transparent border-gray-300 text-purple-heart; */
        background-size: 75% 75%;
        background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="white"%3E%3Cpath d="M10.7987 2.30054C10.7271 2.30059 10.6569 2.32118 10.5966 2.35986C8.3154 3.78299 6.4458 5.69442 4.88589 7.9563C4.09692 6.87483 3.21835 5.92938 2.1437 5.30127C2.10113 5.27456 2.05362 5.25667 2.004 5.24868C1.95438 5.24069 1.90366 5.24276 1.85485 5.25475C1.80604 5.26675 1.76014 5.28844 1.71988 5.31852C1.67962 5.34861 1.64581 5.38648 1.62048 5.42989C1.59514 5.4733 1.57879 5.52135 1.57239 5.5712C1.56599 5.62106 1.56968 5.67169 1.58323 5.72008C1.59678 5.76848 1.61993 5.81366 1.65129 5.85294C1.68264 5.89222 1.72158 5.9248 1.76577 5.94873C2.84913 6.58192 3.76932 7.56959 4.59146 8.78247C4.6261 8.83343 4.67276 8.87508 4.72732 8.90372C4.78188 8.93236 4.84266 8.94711 4.90428 8.94668C4.9659 8.94624 5.02646 8.93063 5.08061 8.90123C5.13476 8.87182 5.18083 8.82952 5.21475 8.77808C6.78039 6.40304 8.67412 4.44333 10.9936 2.99634C11.0636 2.95389 11.1179 2.88966 11.148 2.81348C11.1781 2.7373 11.1825 2.65334 11.1604 2.57445C11.1383 2.49556 11.091 2.42608 11.0257 2.37661C10.9604 2.32715 10.8807 2.30043 10.7987 2.30054Z" fill="white"/%3E%3C/svg%3E');
    }

    input:disabled,
    button:disabled {
        @apply cursor-not-allowed;
    }

    html {
        overflow-x: hidden;
    }

    /* Bubble Shapes */
    .bubble {
        clip-path: url(#bubble);
    }

    .bubble-wide {
        clip-path: url(#bubble-wide);
    }

    .bubble-tall {
        clip-path: url(#bubble-tall);
    }

    .bubble-tall-horizontal {
        clip-path: url(#bubble-tall-horizontal);
    }

    /* Bolt Shapes */
    .bolt {
        clip-path: url(#bolt);
    }

    .bolt-wide {
        clip-path: url(#bolt-wide);
    }

    .bolt-tall {
        clip-path: url(#bolt-tall);
    }

    /* Cube Shapes */
    .cube {
        clip-path: url(#cube);
    }

    .cube-wide {
        clip-path: url(#cube-wide);
    }

    .cube-tall {
        clip-path: url(#cube-tall);
    }

    .cube-large {
        clip-path: url(#cube-large);
    }

    /* Tab Shapes */
    .tab {
        clip-path: url(#tab);
    }

    .tab-wide {
        clip-path: url(#tab-wide);
    }

    .tab-tall {
        clip-path: url(#tab-tall);
    }

    .tab-large {
        clip-path: url(#tab-large);
    }

    /* No Shape */
    .no-shape {
        clip-path: none;
    }

    .clipped-background {
        clip-path: polygon(
            0 0,
            calc(100% - 100px) 0,
            100% 100px,
            100% 100%,
            100px 100%,
            0 calc(100% - 100px)
        );
    }

    :root {
        --color-pink: #e43369;
        --color-blue: #4ba5b9;
        --color-yellow: #f8bb31;

        /* Brand Colours */
        --color-brand-100: rgba(255, 239, 230, 1);
        --color-brand-600: rgba(255, 97, 0, 1);
        --color-brand-800: rgba(206, 79, 0, 1);
        --color-brand-900: rgba(186, 71, 0, 1);

        /* Accent (e.g. background) Colours */
        --color-pf-accent-2-50: rgba(251, 251, 233, 1);
        --color-pf-accent-2-100: rgba(247, 248, 211, 1);
        --color-pf-accent-2-400: rgba(232, 234, 123, 1);
        --color-pf-accent-2-800: rgba(130, 132, 21, 1);

        --color-pf-accent-3-600: rgba(25, 70, 65, 1);

        --color-pf-accent-4-200: rgba(210, 220, 247, 1);

        --color-pf-accent-6-600: rgba(228, 111, 222, 1);

        /* Primary Colours */
        --color-primary-ui-00: rgba(255, 255, 255, 1);
        --color-primary-ui-900: rgba(27, 24, 25, 1);
        --color-primary-ui-900-50: rgba(27, 24, 25, 0.5);

        /* Secondary Colours */
        --color-secondary-ui-50: rgba(242, 242, 242, 1);
        --color-secondary-ui-100: rgba(230, 230, 230, 1);
        --color-secondary-ui-200: rgba(204, 204, 204, 1);
        --color-secondary-ui-300: rgba(153, 153, 153, 1);
        --color-secondary-ui-400: rgba(156, 163, 175, 1);
        --color-secondary-ui-500: rgba(102, 102, 102, 1);
        --color-secondary-ui-600: rgba(75, 85, 99, 1);
        --color-secondary-ui-700: rgba(51, 51, 51, 1);
        --color-secondary-ui-900: rgba(17, 24, 39, 1);
    }
}

@layer components {
    .max-h-notifications-menu {
        max-height: calc(100vh - 250px);
    }

    .max-h-modal {
        max-height: calc(100vh - 150px);
    }
}

@layer utilities {
    .full-width {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
    }

    .bg-tech-blue-gradient-50 {
        background: linear-gradient(
            77.34deg,
            rgba(63, 116, 246, 0.4) -6.86%,
            rgba(128, 235, 252, 0.4) 142.65%
        );
    }

    .text-0 {
        font-size: 0;
    }
}

.pass-score-0 {
    background-color: #af0204;
    transform: scaleX(10%);
}

.pass-score-1 {
    background-color: #e07407;
    transform: scaleX(20%);
}

.pass-score-2 {
    background-color: #ffc600;
    @apply scale-x-50;
}

.pass-score-3 {
    background-color: #bebd01;
    @apply scale-x-75;
}

.pass-score-4 {
    background-color: #4cae01;
    @apply scale-x-100;
}

.drag-drop-target {
    transition: opacity 0.3s ease;
    opacity: 0;
    transform: translateY(-200%);
}

.uppy-is-drag-over .drag-drop-target {
    opacity: 1;
    transform: translateY(0);
}

.uppy-FileInput-container,
.uppy-FileInput-btn {
    @apply bg-transparent !absolute left-0 top-0 h-full w-full;
    font-size: 0;
}

.prose video {
    margin: 0;
}

.assessment-loader {
    transform: translateX(-9999px);
    opacity: 0;
    /* fading out, delay transform after opacity */
    transition: transform 0s 0.4s, opacity 0.4s ease;
}

.assessment-submitting {
    transform: translateX(0);
    opacity: 1;
    /* fading in */
    transition: opacity 0.4s ease;
}

.revoke-form-enter {
    @apply opacity-0 h-0;
}

.revoke-form-enter-active {
    @apply opacity-100 h-24;
    transition: all 0.4s ease;
}

.revoke-form-exit {
    @apply opacity-100 h-24;
}

.revoke-form-exit-active {
    @apply opacity-0 h-0;
    transition: all 0.4s ease;
}

.avatar {
    @apply text-base;
    height: 2.66em;
    width: 2.66em;
}

.avatar .text {
    padding-top: 0.25em;
}

.avatar span {
    font-size: 1em;
}

.bg-tech-dark-green {
    background-color: #05a044;
}

/* Override react-toggle */
.react-toggle-track {
    background-color: #4d4d4d !important;
}

.react-toggle-thumb {
    border-color: #4d4d4d !important;
}

.react-toggle--focus .react-toggle-thumb {
    box-shadow: 0 0 2px 3px rgba(255, 255, 255, 0.5) !important;
}

.prose h1 {
    @apply text-tuna font-bold text-4xl mb-6 mt-0;
}

.prose h2 {
    @apply text-tuna font-medium text-3xl mb-5 mt-0;
}

.prose h3 {
    @apply text-tuna font-medium text-2xl mb-4 mt-0;
}

.prose h4 {
    @apply text-tuna font-medium text-xl mb-3 mt-0;
}

.prose h5 {
    @apply text-tuna font-medium text-lg mb-2 mt-0;
}

.prose h6 {
    @apply text-tuna font-medium text-base mb-2 mt-0;
}

.prose ul > li {
    @apply before:bg-purple-heart before:w-2 before:h-2;
}
