/**
 * @license
 * MyFonts Webfont Build ID 3276087, 2016-08-29T18:29:02-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: AvenirLT-Medium by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/65-medium/
 *
 * Webfont: AvenirLT-Roman by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/55-roman/
 *
 * Webfont: AvenirLT-MediumOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/65-mediumoblique/
 *
 * Webfont: AvenirLT-BookOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/45-book-oblique/
 *
 * Webfont: AvenirLT-Black by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/95-black/
 *
 * Webfont: AvenirLT-HeavyOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/85-heavy-oblique/
 *
 * Webfont: AvenirLT-Book by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/45-book/
 *
 * Webfont: AvenirLT-Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/35-light/
 *
 * Webfont: AvenirLT-BlackOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/95-black-oblique/
 *
 * Webfont: AvenirLT-LightOblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/35-light-oblique/
 *
 * Webfont: AvenirLT-Oblique by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/55-oblique/
 *
 * Webfont: AvenirLT-Heavy by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/85-heavy/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3276087
 * Licensed pageviews: 250,000
 * Webfonts copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 1981 - 2007 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be repro
 *
 * © 2016 MyFonts Inc
 */

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/31fd37");

/* @font-face {
  font-family: 'AvenirLT-Medium';
  src: url('/fonts/31FD37_0_0.eot');
  src: url('/fonts/31FD37_0_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/31FD37_0_0.woff2') format('woff2'),
    url('/fonts/31FD37_0_0.woff') format('woff'),
    url('/fonts/31FD37_0_0.ttf') format('truetype');
} */

/* AvenirLT */
@font-face {
    font-family: "Avenir";
    src: url("/fonts/31FD37_1_0.eot");
    src: url("/fonts/31FD37_1_0.eot?#iefix") format("embedded-opentype"),
        url("/fonts/31FD37_1_0.woff2") format("woff2"),
        url("/fonts/31FD37_1_0.woff") format("woff"),
        url("/fonts/31FD37_1_0.ttf") format("truetype");
    font-weight: 400;
}

/* @font-face {
  font-family: 'AvenirLT-MediumOblique';
  src: url('/fonts/31FD37_2_0.eot');
  src: url('/fonts/31FD37_2_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/31FD37_2_0.woff2') format('woff2'),
    url('/fonts/31FD37_2_0.woff') format('woff'),
    url('/fonts/31FD37_2_0.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'AvenirLT-BookOblique';
  src: url('/fonts/31FD37_3_0.eot');
  src: url('/fonts/31FD37_3_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/31FD37_3_0.woff2') format('woff2'),
    url('/fonts/31FD37_3_0.woff') format('woff'),
    url('/fonts/31FD37_3_0.ttf') format('truetype');
} */

/* AvenirLT-Black */
@font-face {
    font-family: "Avenir";
    src: url("/fonts/31FD37_4_0.eot");
    src: url("/fonts/31FD37_4_0.eot?#iefix") format("embedded-opentype"),
        url("/fonts/31FD37_4_0.woff2") format("woff2"),
        url("/fonts/31FD37_4_0.woff") format("woff"),
        url("/fonts/31FD37_4_0.ttf") format("truetype");
    font-weight: 700;
}

/* @font-face {
  font-family: 'AvenirLT-HeavyOblique';
  src: url('/fonts/31FD37_5_0.eot');
  src: url('/fonts/31FD37_5_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/31FD37_5_0.woff2') format('woff2'),
    url('/fonts/31FD37_5_0.woff') format('woff'),
    url('/fonts/31FD37_5_0.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'AvenirLT-Book';
  src: url('/fonts/31FD37_6_0.eot');
  src: url('/fonts/31FD37_6_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/31FD37_6_0.woff2') format('woff2'),
    url('/fonts/31FD37_6_0.woff') format('woff'),
    url('/fonts/31FD37_6_0.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'AvenirLT-Light';
  src: url('/fonts/31FD37_7_0.eot');
  src: url('/fonts/31FD37_7_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/31FD37_7_0.woff2') format('woff2'),
    url('/fonts/31FD37_7_0.woff') format('woff'),
    url('/fonts/31FD37_7_0.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'AvenirLT-BlackOblique';
  src: url('/fonts/31FD37_8_0.eot');
  src: url('/fonts/31FD37_8_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/31FD37_8_0.woff2') format('woff2'),
    url('/fonts/31FD37_8_0.woff') format('woff'),
    url('/fonts/31FD37_8_0.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'AvenirLT-LightOblique';
  src: url('/fonts/31FD37_9_0.eot');
  src: url('/fonts/31FD37_9_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/31FD37_9_0.woff2') format('woff2'),
    url('/fonts/31FD37_9_0.woff') format('woff'),
    url('/fonts/31FD37_9_0.ttf') format('truetype');
} */

/* @font-face {
  font-family: 'AvenirLT-Oblique';
  src: url('/fonts/31FD37_A_0.eot');
  src: url('/fonts/31FD37_A_0.eot?#iefix') format('embedded-opentype'),
    url('/fonts/31FD37_A_0.woff2') format('woff2'),
    url('/fonts/31FD37_A_0.woff') format('woff'),
    url('/fonts/31FD37_A_0.ttf') format('truetype');
} */

/* Inter */
/* Regular */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

/* Italic */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

/* Thin */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}

/* Thin Italic */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-ThinItalic.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}

/* ExtraLight */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
}

/* ExtraLight Italic */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-ExtraLightItalic.ttf") format("truetype");
    font-weight: 200;
    font-style: italic;
}

/* Light */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

/* Light Italic */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
}

/* Medium */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

/* Medium Italic */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

/* SemiBold */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

/* SemiBold Italic */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

/* Bold */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

/* Bold Italic */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}

/* ExtraBold */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}

/* ExtraBold Italic */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-ExtraBoldItalic.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}

/* Black */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

/* Black Italic */
@font-face {
    font-family: "Inter";
    src: url("/fonts/Inter_18pt-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-style: italic;
}

/* Lora */
/* Regular */
@font-face {
    font-family: "Lora";
    src: url("/fonts/Lora-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

/* Italic */
@font-face {
    font-family: "Lora";
    src: url("/fonts/Lora-Italic.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}

/* Medium */
@font-face {
    font-family: "Lora";
    src: url("/fonts/Lora-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

/* Medium Italic */
@font-face {
    font-family: "Lora";
    src: url("/fonts/Lora-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic;
}

/* SemiBold */
@font-face {
    font-family: "Lora";
    src: url("/fonts/Lora-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

/* SemiBold Italic */
@font-face {
    font-family: "Lora";
    src: url("/fonts/Lora-SemiBoldItalic.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
}

/* Bold */
@font-face {
    font-family: "Lora";
    src: url("/fonts/Lora-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

/* Bold Italic */
@font-face {
    font-family: "Lora";
    src: url("/fonts/Lora-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
